require('./bootstrap');
import * as coreui from '@coreui/coreui';
window.coreui = coreui;
require('select2');
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import 'datatables.net-datetime';
import 'datatables.net-rowreorder-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'bootstrap-notify';
window.moment = require('moment');
window.SignaturePad = require('signature_pad');

import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});
